<template>
    <div class='h-full relative'>
        <NavBar
            :user='$store.state.user'
            :user-organizations='userOrganizations'
            :organization='organization'
            :help-url='organization.isDirectCustomer ? $t("help-url-dc") : $t("help-url")'
            :key='navBarKey'
            @open-new-environment-popup='showAddNewEnvironmentPopup'
            @open-new-organization-popup='$emit("openNewOrganizationPopup")'
        />
        <router-view :organization='organization' v-if='loaded' @update:organization='updateOrganization' />
        <div
            v-if='loaded && $route.name === "organization" && !userHasEnvironmentsAccess'
            class='container flex flex-col items-center justify-center h-full'
        >
            <div
                v-if='organization.isDirectCustomer'
                class='container flex flex-col items-center justify-center mb-12'
            >
                <CustomTitle class='mb-6 text-center'>
                    {{ $t('add-new-environment.title-part-1') }}
                    <br>
                    {{ $t('add-new-environment.title-part-2') }}
                </CustomTitle>
                <FriendlyButton
                    label='add-new-environment.btn-open-popup'
                    :action='showAddNewEnvironmentPopup'
                    square
                    small
                    no-margin
                />
                <Tooltip class='mt-8'>
                    <template #trigger>
                        <div class='flex gap-2'>
                            <Icon name='InformationCircle' class='text-grey-500' family='outline' />
                            <p class='text-lg text-grey-500'>
                                {{ $t('add-new-environment.what-is-an-env.label') }}
                            </p>
                        </div>
                    </template>
                    <template #content>
                        {{ $t('add-new-environment.what-is-an-env.text') }}
                    </template>
                </Tooltip>
            </div>
            <div v-else>
                <CustomTitle class='mb-6 font-black text-center'>
                    {{ $t('ttl-org-without-environment') }}
                </CustomTitle>
                <p class='text-center text-xl max-w-4xl mx-auto'>
                    {{ $t('p-org-without-environment') }}
                </p>
            </div>
        </div>
        <AddNewEnvironmentPopup
            :organization='organization'
            :is-shown='isAddNewEnvironmentPopupShown'
            :close='closeAddNewEnvironmentPopup'
            @update:organization='updateOrganization'
        />
    </div>
    <OnboardingHelper :organization='organization' v-if='organization.id && organization.isDirectCustomer' />
</template>

<script>
    import { mapState } from 'vuex';
    import { gql } from '@apollo/client/core';
    import Loader from '../loader.js';
    import NavBar from '@/components/NavBar';
    import notify from '@/notify.js';
    import CustomTitle from '@/components/Title';
    import bus from '@/bus';
    import { datadogRum } from '@datadog/browser-rum';
    import OnboardingHelper from '@/user/OnboardingHelper';
    import Tooltip from '@/components/Tooltip.vue';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Icon from '@/components/Icon.vue';
    import AddNewEnvironmentPopup from '@/organization/AddNewEnvironmentPopup.vue';

    export default {
        name: 'OrganizationView',
        components: {
            Icon,
            FriendlyButton,
            Tooltip,
            NavBar,
            CustomTitle,
            OnboardingHelper,
            AddNewEnvironmentPopup,
        },
        data () {
            return {
                loaded: false,
                organization: {},
                userOrganizations: [],
                navBarKey: 1,
                isAddNewEnvironmentPopupShown: false,
            };
        },
        emits: ['openNewOrganizationPopup'],
        computed: {
            ...mapState({
                user: state => state.user,
            }),
            userHasEnvironmentsAccess () {
                return this.organization && this.organization.environments && this.organization.environments.length > 0;
            },
        },
        watch: {
            '$route.params.organizationId': async function (newValue) {
                await this.loadOrganization(newValue);
                this.environmentRedirect(this.$route.params);
            },
            organization: function (value) {
                const event = {
                    userEmail: this.$store.state.user.email,
                    enterpriseName: value.enterpriseName,
                    orgType: value.isDirectCustomer ? 'directCustomer' : 'accountant',
                    role: this.$store.state.user.organizations.find(org => org.organizationId === value.id).role,
                };
                if (!this.$store.getters.hasLoginRUMBeenSent) {
                    // Gets sent only once, at the start of the session
                    datadogRum.addAction('login_completed', event);
                    this.$store.commit('setSentLoginRUM');
                } else {
                    // Gets sent every time the organization is updated
                    datadogRum.addAction('organization_switched', event);
                }
            },
        },
        async beforeRouteUpdate (to, from, next) {
            // default redirect on route organization
            if (
                to.name === 'organization' &&
                to.params.organizationId === from.params.organizationId
            ) {
                await this.loadOrganization(to.params.organizationId);
                this.environmentRedirect(this.$route.params);
            }

            next();
        },
        async mounted () {
            // goal here is to load the list of organizations the user has access to, if they have access to multiple
            await this.loadOrganization(this.$route.params.organizationId);
            // goal here is to load the organization information and environment list
            if (this.$route.name === 'organization') {
                this.environmentRedirect(this.$route.params);
            }
            bus.on('environmentUpdate',  ({ id, name }) => {
                this.organization.environments.find(env => env.id === id).name = name;
            });
        },
        methods: {
            async loadOrganization (organizationId) {
                const loader = Loader.start();
                // Check if user's organizations are fetched
                await this.loadAppData();
                // Query the right organization from the collection
                this.organization = this.userOrganizations.find(organization => organization.id === organizationId);
                // Not found? We can refresh ONCE
                if (!this.organization) {
                    notify.error(this.$t('ttl-err-no-access'));
                    this.$router.push('/');
                    return;
                }
                this.$store.commit('setIsDirectCustomer', this.organization.isDirectCustomer);
                // this is a work around to force vue to update the NavBar
                this.navBarKey += 1;
                localStorage.selectedOrganizationId = this.organization.id;
                Loader.stop(loader);
                this.loaded = true;
            },
            async loadAppData () {
                try {
                    const organizationIds = this.$store.state.user.organizationIds;
                    const query = gql`
                              query appData($organizationIds: [String!]!) {
                                organizations(organizationIds: $organizationIds) {
                                  id
                                  enterpriseName
                                  enterpriseNumber
                                  hasBelgianVatNumber
                                  vatNumber
                                  address
                                  address2
                                  zip
                                  city
                                  representativeName
                                  representativeFunction
                                  representativeEmailAddress
                                  representativePhoneNumber
                                  invoicingEmailAddress
                                  delegatesMandateDelivery
                                  isDirectCustomer
                                  hasBelgianVatNumber
                                  hasCodaOnlyContract
                                  created
                                  environments {
                                    id
                                    accountantId
                                    softwareId
                                    softwareName
                                    name
                                    isExactOnline
                                    language
                                    contactEmail
                                    contactName
                                    state
                                    ftpMigrationState
                                  }
                                  syncToolConnections {
                                    id
                                    name
                                    lastSynced
                                    userAgent
                                  }
                                }
                                banks {
                                    id
                                    name
                                    slug
                                    abbreviation
                                    isSupported
                                    isTwikeySupported
                                    disableSendPdfMandate
                                    isCaroSupported
                                    isStopCodaSupported
                                    regexSavings
                                }
                              }
                              `;
                    const variables = { organizationIds };
                    const { data } = await this.$apollo.query({
                        query,
                        variables,
                    });
                    this.$store.commit('setAllBanks', data.banks || []);
                    this.userOrganizations = data.organizations;
                } catch (e) {
                    notify.error(this.$t('ttl-err-no-access'));
                    this.$router.push('/');
                }
            },
            environmentRedirect (params) {
                if (this.userHasEnvironmentsAccess) {
                    const previouslySelectedEnvId = localStorage.selectedEnvironmentId;
                    let environmentId;
                    if (this.organization.environments.some(f => f.id === params.environmentId)) {
                        // priority to the environment set by the url, if it exists in the current org
                        environmentId = params.environmentId;
                    } else if (this.organization.environments.some(f => f.id === previouslySelectedEnvId)) {
                        // if no params, use what has been saved locally, if it exists in the current org
                        environmentId = previouslySelectedEnvId;
                    } else {
                        // otherwise, take the first of the organization
                        environmentId = this.organization.environments[0].id;
                    }
                    if (environmentId) {
                        this.$router.push(`/organization/${this.organization.id}/environment/${environmentId}`);
                    }
                }
            },
            updateOrganization (organization = this.organization) {
                this.loadOrganization(organization.id);
            },
            showAddNewEnvironmentPopup () {
                this.isAddNewEnvironmentPopupShown = true;
            },
            closeAddNewEnvironmentPopup () {
                this.isAddNewEnvironmentPopupShown = false;
            },
        },

    };
</script>
