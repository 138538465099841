<script>
    import VoilaTermsFr from '../components/VoilaTermsFr.vue';
    import VoilaTermsEn from '../components/VoilaTermsEn.vue';
    import VoilaTermsNl from '../components/VoilaTermsNl.vue';
    import CodaTermsEn from '../components/CodaTermsEn';
    import CodaTermsFr from '../components/CodaTermsFr';
    import CodaTermsNl from '../components/CodaTermsNl';
    import SodaTermsEn from '../components/SodaTermsEn';
    import SodaTermsFr from '../components/SodaTermsFr';
    import SodaTermsNl from '../components/SodaTermsNl';
    import CcsTermsEn from '../components/CcsTermsEn';
    import CcsTermsFr from '../components/CcsTermsFr';
    import CcsTermsNl from '../components/CcsTermsNl';
    import utils from '../utils.js';
    import CustomTitle from '@/components/Title';
    import ContentBox from '@/components/ContentBox';

    export default {
        name: 'EnvServices',

        props: {
            organization: Object,
        },

        components: {
            'voila-terms-fr': VoilaTermsFr,
            'voila-terms-en': VoilaTermsEn,
            'voila-terms-nl': VoilaTermsNl,
            'coda-terms-en': CodaTermsEn,
            'coda-terms-fr': CodaTermsFr,
            'coda-terms-nl': CodaTermsNl,
            'soda-terms-en': SodaTermsEn,
            'soda-terms-fr': SodaTermsFr,
            'soda-terms-nl': SodaTermsNl,
            'ccs-terms-en': CcsTermsEn,
            'ccs-terms-fr': CcsTermsFr,
            'ccs-terms-nl': CcsTermsNl,
            CustomTitle,
            ContentBox,
        },

        data () {
            return {
                activeService: null,
            };
        },

        computed: {
            locale () {
                return this.$i18n.locale;
            },
            hasCodaOnlyContract () {
                return this.organization.hasCodaOnlyContract;
            },
            isDirectCustomer () {
                return this.organization.isDirectCustomer;
            },
        },

        methods: {
            showServiceInfo (service) {
                if (service === this.activeService) {
                    this.activeService = null;
                } else {
                    this.activeService = service;
                    utils.scrollTop();
                }
            },
        },
    };
</script>

<template>
    <section>
        <CustomTitle class='mt-6 mb-6'>
            {{ $t('h-contracts') }}
        </CustomTitle>

        <content-box class='cb-services-list'>
            <div class='cb-service pb-3' :class='{open: activeService === "coda-delivery"}'>
                <div class='cb-service-item border-0 border-b border-solid border-grey-200' @click.prevent='showServiceInfo("coda-delivery")'>
                    <div class='cb-service-title'>
                        {{ $t('h-coda-delivery') }}
                    </div>
                    <div class='cb-service-info'>
                        <i class='fa fa-chevron-right'></i>
                    </div>
                </div>
                <div class='cb-service-info-box'>
                    <div class='cb-terms-conditions'>
                        <coda-terms-fr v-if='locale === "fr_FR"' :is-direct-customer='isDirectCustomer' :has-coda-only-contract='hasCodaOnlyContract' />
                        <coda-terms-en v-if='locale === "en_US"' :is-direct-customer='isDirectCustomer' :has-coda-only-contract='hasCodaOnlyContract' />
                        <coda-terms-nl v-if='locale === "nl_BE"' :is-direct-customer='isDirectCustomer' :has-coda-only-contract='hasCodaOnlyContract' />
                    </div>
                </div>
            </div>
            <template v-if='!hasCodaOnlyContract'>
                <div class='cb-service pb-3' :class='{ open: activeService === "soda-delivery" }'>
                    <div class='cb-service-item border-0 border-b border-solid border-grey-200' @click.prevent='showServiceInfo("soda-delivery")'>
                        <div class='cb-service-title'>
                            {{ $t('h-soda-delivery') }}
                        </div>
                        <div class='cb-service-info'>
                            <i class='fa fa-chevron-right'></i>
                        </div>
                    </div>
                    <div class='cb-service-info-box'>
                        <div class='cb-terms-conditions'>
                            <soda-terms-fr v-if='locale === "fr_FR"' :is-direct-customer='isDirectCustomer' />
                            <soda-terms-en v-if='locale === "en_US"' :is-direct-customer='isDirectCustomer' />
                            <soda-terms-nl v-if='locale === "nl_BE"' :is-direct-customer='isDirectCustomer' />
                        </div>
                    </div>
                </div>

                <div class='cb-service pb-3' :class='{ open: activeService === "purchase-invoice-delivery" }' v-if='!isDirectCustomer'>
                    <div class='cb-service-item border-0 border-b border-solid border-grey-200' @click.prevent='showServiceInfo("purchase-invoice-delivery")'>
                        <div class='cb-service-title'>
                            {{ $t('h-purchase-invoice') }}
                        </div>
                        <div class='cb-service-info'>
                            <i class='fa fa-chevron-right'></i>
                        </div>
                    </div>
                    <div class='cb-service-info-box'>
                        <div class='cb-terms-conditions'>
                            <voila-terms-fr v-if='locale === "fr_FR"' />
                            <voila-terms-en v-if='locale === "en_US"' />
                            <voila-terms-nl v-if='locale === "nl_BE"' />
                        </div>
                    </div>
                </div>
                <div class='cb-service' :class='{open: activeService === "ccs-delivery"}'>
                    <div class='cb-service-item' @click.prevent='showServiceInfo("ccs-delivery")'>
                        <div class='cb-service-title'>
                            {{ $t('h-ccs-delivery') }}
                        </div>
                        <div class='cb-service-info'>
                            <i class='fa fa-chevron-right'></i>
                        </div>
                    </div>
                    <div class='cb-service-info-box'>
                        <div class='cb-terms-conditions'>
                            <ccs-terms-fr v-if='locale === "fr_FR"' :is-direct-customer='isDirectCustomer' />
                            <ccs-terms-en v-if='locale === "en_US"' :is-direct-customer='isDirectCustomer' />
                            <ccs-terms-nl v-if='locale === "nl_BE"' :is-direct-customer='isDirectCustomer' />
                        </div>
                    </div>
                </div>
            </template>
        </content-box>
    </section>
</template>

<style scoped lang="postcss">
    /* ----------------- *\
   *   SERVICES LIST   *
  \* ----------------- */

  .cb-services-list {
      margin-bottom: 300px;
  }

  .cb-service-title {
      display: inline-block;
      font-size: 20px;
      line-height: 32px;
  }

  .cb-service-item {
      @apply pb-3;
      cursor: pointer;
  }

  .cb-service-info {
      display: inline-block;
      line-height: 20px;
      height: 20px;
      opacity: 1;
      margin-left: 10px;
      color: #0d52ff;
      vertical-align: baseline;
      transform: rotate(0deg);
      transition: transform 150ms ease;
  }
  .cb-service-item:hover .cb-service-info {
      transform: scale(1.3);
  }
  .cb-service.open .cb-service-info {
      transform: rotate(90deg);
  }

  .cb-service-status {
      float: right;
  }

  .cb-service-info-box {
      @apply mt-3;
      display: none;
      overflow: hidden;
      border-bottom: solid 1px #eeeeee;
  }
  .cb-service.open .cb-service-info-box {
      display: block;
  }
  .cb-service.open .cb-service-item .btn-buy {
      background: #70C36B;
      border-color: #70C36B;
      color: white;
      opacity: 0.1;
  }
  .cb-accept-terms {
      margin-bottom: 10px;
  }
  .cb-terms-conditions {
      overflow: auto;
      padding-left: 10px;
      margin-bottom: 10px;
  }
</style>
