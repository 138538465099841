<script>
    import ListView from '../components/ListView.js';
    import EnterpriseNumber from '../components/EnterpriseNumber.vue';
    import ContentBox from '../components/ContentBox.vue';
    import Dropdown from '@/components/DropdownV2';
    import DropdownGroup from '@/components/DropdownGroup';
    import DropdownItem from '@/components/DropdownItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import SearchInput from '@/components/SearchInput';
    import CustomTitle from '@/components/Title';
    import CopyToClipBoard from '@/components/CopyToClipBoard.vue';
    import { useQuery } from '@vue/apollo-composable';
    import { gql } from '@apollo/client/core';
    import { datadogRum } from '@datadog/browser-rum';
    import { hashString } from '@/utils.js';

    export default {
        name: 'ResellerEnvironmentsSearch',
        mixins: [ListView],
        props: {
            currentReseller: {
                type: Object,
            },
        },
        components: {
            EnterpriseNumber,
            ContentBox,
            Dropdown,
            DropdownGroup,
            DropdownItem,
            FriendlyButton,
            SearchInput,
            CustomTitle,
            CopyToClipBoard,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/reseller/environments',
                defaultSortKey: 'name',
            });

            // configure organization filter
            const orgFilterOptions = [{
                value: 'all-organizations',
                label: 'opt-all-organizations',
                filter: null,
            }];

            for (const org of this.currentReseller.organizations) {
                orgFilterOptions.push({
                    value: org.id,
                    label: org.enterpriseName,
                    filter: { organizationIds: [org.id] },
                });
            }

            this.configureSelectFilter(data, 'organization', orgFilterOptions);

            // configure state filter
            this.configureSelectFilter(data, 'environmentState', [
                {
                    value: 'all-environments',
                    label: 'opt-all-environments',
                    filter: null,
                },
                {
                    value: 'archived',
                    label: 'opt-archived-environments',
                    filter: { state: 'archived' },
                },
                {
                    value: 'active',
                    label: 'opt-active-environments',
                    filter: { state: 'active' },
                },
            ]);

            return {
                ...data,
                softwareList: [],
            };
        },
        mounted () {
            this.watchFilters();
            this.search();
            this.fetchSoftwareList();
        },
        methods: {
            dynamicSearchParams () {
                return { resellerIds: [this.currentReseller.id] };
            },
            async fetchSoftwareList () {
                const { result, onResult, onError } = useQuery(gql`
                    query softwareList($resellerId: String) {
                        softwareList(resellerId: $resellerId) {
                            results {
                                id,
                                name,
                                slug,
                            }
                        }
                    }
                `, {
                    resellerId: this.currentReseller.id,
                });

                onResult(() => {
                    if (result.value && result.value.softwareList && result.value.softwareList.results) {
                        // Filter out software that isn't public if needed
                        this.softwareList = result.value.softwareList.results;
                    }
                });

                onError(() => {
                    // If there's an error, we just don't show the software list
                    this.softwareList = [];
                });
            },
            getInvitationLink (softwareId) {
                const baseUrl = window.location.origin;

                // Create the referral object with reseller info and software ID
                const referralData = {
                    reseller: {
                        id: this.currentReseller.id,
                        name: this.currentReseller.name,
                    },
                    softwareId,
                };

                // Encode the referral data as base64
                const encodedReferral = btoa(JSON.stringify(referralData))
                    .replace(/\+/g, '-')
                    .replace(/\//g, '_')
                    .replace(/=+$/, '');

                return `${baseUrl}/#/sign-up?referral=${encodedReferral}`;
            },
            trackInvitationLinkCopied (softwareId, softwareName) {
                datadogRum.addAction('reseller_invitation_link_copied', {
                    hashedResellerId: hashString(this.currentReseller.id),
                    resellerName: this.currentReseller.name,
                    softwareId,
                    softwareName,
                });
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-env-title flex justify-between items-center'>
            <CustomTitle class='mt-12 mb-6'>
                {{ $t('h-environments') }}
            </CustomTitle>
            <div class='mt-12 mb-6'>
                <Dropdown
                    direction='right'
                    :name='$t("invite-client.title")'
                    icon='UserPlus'
                    no-rotation
                    id='reseller-invite-client-dropdown'
                >
                    <DropdownGroup>
                        <div class='p-4 pt-2 w-96'>
                            <div class='mb-3 text-gray-600'>
                                {{ $t('invite-client.description') }}
                            </div>
                            <div v-for='software in softwareList' :key='software.id' class='mb-3 last:mb-0'>
                                <div class='text-sm font-medium mb-1'>
                                    {{ software.name }}
                                </div>
                                <CopyToClipBoard
                                    :text='getInvitationLink(software.id)'
                                    :ellipse='true'
                                    @click='trackInvitationLinkCopied(software.id, software.name)'
                                />
                            </div>
                        </div>
                    </DropdownGroup>
                </Dropdown>
            </div>
        </div>

        <content-box>
            <div class='flex'>
                <SearchInput v-model='list.textsearch' class='mr-3' />

                <Dropdown
                    v-model='list.filters.organization.selected'
                    class='mr-3'
                    default-value='all-organizations'
                    direction='right'
                >
                    <DropdownGroup scrollable>
                        <DropdownItem v-for='option in list.filters.organization.options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                            {{ $t(option.label) }}
                        </DropdownItem>
                    </DropdownGroup>
                </Dropdown>

                <Dropdown
                    v-model='list.filters.environmentState.selected'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownGroup scrollable>
                        <DropdownItem v-for='option in list.filters.environmentState.options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                            {{ $t(option.label) }}
                        </DropdownItem>
                    </DropdownGroup>
                </Dropdown>
            </div>
            <pagination
                :list='list'
                :pagination='pagination'
                :ctrls='getControls()'
                size='small'
                class='my-6'
            />

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='organization_name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='enterprise_num' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-organization-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='organization_name' />
                            </th>
                            <th>
                                <span>{{ $t('th-environment-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='name' />
                            </th>
                            <th>
                                <span>{{ $t('th-enterprise-number') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='enterprise_num' />
                            </th>
                            <th>
                                <span>{{ $t('th-status') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-documents') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(record, i) in list.records' :key='i'>
                            <td>{{ record.organization_name }}</td>
                            <td>{{ record.name }}</td>
                            <td><enterprise-number :enterprise-number='record.enterprise_num' /></td>
                            <td>{{ record.state }}</td>
                            <td>
                                <template v-if='record.state !== "archived"'>
                                    <FriendlyButton
                                        label='btn-codas'
                                        :action='() => {
                                            $router.push({
                                                name:"reseller-coda-search",
                                                query: {
                                                    environmentId: record.id
                                                },
                                                params: {
                                                    resellerId: currentReseller.id
                                                }
                                            })
                                        }'
                                        square
                                        extra-small
                                        micro
                                        no-margin
                                        class='mr-3'
                                    />
                                    <FriendlyButton
                                        label='btn-sodas'
                                        :action='() => {
                                            $router.push({
                                                name:"reseller-soda-search",
                                                query: {
                                                    environmentId: record.id
                                                },
                                                params: {
                                                    resellerId: currentReseller.id
                                                }
                                            })
                                        }'
                                        square
                                        extra-small
                                        micro
                                        no-margin
                                    />
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>
