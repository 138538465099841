<script setup>
import { onMounted, ref, computed } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import Wizard from '@/components/Wizard';
import WizardStep from '@/components/WizardStep';
import WizardButtons from '@/components/WizardButtons';
import WizardNextButton from '@/components/WizardNextButton';
import WizardPreviousButton from '@/components/WizardPreviousButton';
import WizardStepContent from '@/components/WizardStepContent';
import { Form } from 'vee-validate';
import FormInput from '@/components/FormInput';
import FormCheckbox from '@/components/FormCheckbox';
import Popup from '@/clientcomponents/Popup.vue';
import validate from '@/validate';
import notify from '@/notify';
import { t } from '@/i18n';
import Tooltip from '@/components/Tooltip.vue';
import Icon from '@/components/Icon';
import CustomTitle from '@/components/Title';
import { useMutation } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import Loader from '../loader.js';

const props = defineProps({
    organization: {
        type: Object,
        required: true,
    },
    environmentId: {
        type: String,
        required: true,
    },
    user: {
        type: Object,
        required: true,
    },
});

const representativeName = ref('');
const representativeEmailAddress = ref('');
const representativeFunction = ref('');
const migratingFinished = ref(false);

const emits = defineEmits(['migrated']);

onMounted(() => {
    datadogRum.addAction('contract_migration_started');
});

function submitLegalRepresentativeStep (values, nextStep) {
    representativeName.value = values.representativeName;
    representativeEmailAddress.value = values.representativeEmailAddress;
    representativeFunction.value = values.representativeFunction;
    nextStep();
}

const shouldShowPopup = computed(() => {
    if (!props.organization.hasCodaOnlyContract) {
        return false;
    }
    if (migratingFinished.value) {
        return false;
    }
    if (props.user.organizations.some(org => org.organizationId === props.organization.id && org.role === 'support')) {
        return false;
    }
    return true;
});

async function migrateContract (values) {
    const loader = Loader.start();
    const { mutate: upgradeContractToAllProducts } = useMutation(gql`
        mutation upgradeContractToAllProducts($input: UpgradeContractInput!) {
            upgradeContractToAllProducts(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);
    try {
        const data = (await upgradeContractToAllProducts({
            input: {
                organizationId: props.organization.id,
                representativeName: representativeName.value,
                representativeEmailAddress: representativeEmailAddress.value,
                representativeFunction: representativeFunction.value,
                environmentId: props.environmentId,
            },
        })).data.upgradeContractToAllProducts;

        if (data.errors && data.errors.length > 0) {
            datadogRum.addAction('contract_migration_error', {
                errors: data.errors,
            });
            validate.reportGQLFieldErrors(data.errors);
            notify.error(t('err-unknown'));
        } else {
            datadogRum.addAction('contract_migration_completed_success');
            emits('migrated');
        }
    } catch (error) {
        console.log(error);
        notify.error(t('err-unknown'));
    }
    migratingFinished.value = true;
    Loader.stop(loader);
}
</script>

<template>
    <Popup :show='shouldShowPopup' not-closable class='no-popup-title'>
        <template #header>
        </template>
        <Wizard v-slot='{ previousStep, nextStep }' full-width class='overflow-hidden'>
            <!-- Step 1: Info -->
            <WizardStep title='contract-migration-popup.steps.info.title' icon='BookOpen'>
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('contract-migration-popup.steps.info.content-title') }}
                    </CustomTitle>
                    <div class='mt-4'>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <p v-html='$t("contract-migration-popup.steps.info.explanation")'></p>
                    </div>
                    <WizardButtons>
                        <WizardNextButton
                            @click='nextStep'
                            id='info-next-step'
                            label='contract-migration-popup.btn-next'
                        />
                    </WizardButtons>
                </WizardStepContent>
            </WizardStep>

            <!-- Step 2: Legal Representative -->
            <WizardStep title='contract-migration-popup.steps.legal-representative.title' icon='User'>
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('contract-migration-popup.steps.legal-representative.content-title') }}
                    </CustomTitle>
                    <Tooltip
                        faded
                        class='self-start'
                    >
                        <template #trigger>
                            <div class='flex items-center gap-1 text-grey-500'>
                                <Icon name='InformationCircle' family='outline' />
                                {{ $t('lbl-why-important') }}
                            </div>
                        </template>
                        <template #content>
                            {{ $t('contract-migration-popup.steps.legal-representative.why-important.text') }}
                        </template>
                    </Tooltip>
                    <Form
                        tag='div'
                        v-slot='{ values, handleSubmit }'
                        class='mt-6 grow flex flex-col'
                    >
                        <FormInput
                            name='representativeName'
                            :value='organization.representativeName'
                            :label='$t("contract-migration-popup.steps.legal-representative.lbl-name")'
                            :placeholder='$t("contract-migration-popup.steps.legal-representative.placeholder-name")'
                            rules='composedName|required|max:100'
                            edit
                            id='representativeName'
                            clean
                            class='mb-3'
                        />
                        <FormInput
                            name='representativeEmailAddress'
                            :value='organization.representativeEmailAddress'
                            :label='$t("contract-migration-popup.steps.legal-representative.lbl-email")'
                            :placeholder='$t("contract-migration-popup.steps.legal-representative.placeholder-email")'
                            rules='email|required'
                            edit
                            id='representativeEmailAddress'
                            clean
                            class='mb-3'
                        />
                        <FormInput
                            name='representativeFunction'
                            :value='organization.representativeFunction'
                            :label='$t("contract-migration-popup.steps.legal-representative.lbl-function")'
                            :placeholder='$t("contract-migration-popup.steps.legal-representative.placeholder-function")'
                            rules='required|max:128'
                            edit
                            id='representativeFunction'
                        />
                        <WizardButtons>
                            <WizardPreviousButton
                                @click='previousStep'
                                label='contract-migration-popup.btn-previous'
                            />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitLegalRepresentativeStep(values, nextStep) })'
                                id='legal-representative-next-step'
                                label='contract-migration-popup.btn-confirm'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>

            <!-- Step 3: Terms and Conditions -->
            <WizardStep title='contract-migration-popup.steps.terms-and-conditions.title' icon='DocumentCheck'>
                <WizardStepContent bordered class='h-full flex flex-col'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('contract-migration-popup.steps.terms-and-conditions.content-title') }}
                    </CustomTitle>
                    <p class='mt-4'>
                        {{ $t('contract-migration-popup.steps.terms-and-conditions.description') }}
                    </p>
                    <Form tag='div' v-slot='{ values, handleSubmit }' class='mt-6 flex flex-col'>
                        <FormCheckbox
                            name='termsAndConditions'
                            :html-label='$t("contract-migration-popup.steps.terms-and-conditions.label-checkbox")'
                            edit
                        />
                        <WizardButtons>
                            <WizardPreviousButton
                                @click='previousStep'
                                label='contract-migration-popup.btn-previous'
                            />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { migrateContract(values) })'
                                id='migrate-contract'
                                :disabled='!values.termsAndConditions'
                                :loading='Loader.getState().loading'
                                label='contract-migration-popup.btn-accept-and-close'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>
        </Wizard>
    </Popup>
</template>
<style>
.no-popup-title h1.popup-header {
    height: 0;
    margin-top: 3.5rem;
}
</style>
