<script>
    import { gql } from '@apollo/client/core';
    import ContentBox from '@/components/ContentBox.vue';
    import FormToggle from '@/components/FormToggle';
    import notify from '@/notify';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { Form } from 'vee-validate';
    import Loader from '@/loader';

    export default {
        name: 'SendSodaCodaByMail',
        props: {
            clientV2: { type: Object, required: true },
            organization: { type: Object, required: true },
        },
        components: {
            ContentBox,
            FormToggle,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
            FriendlyButton,
        },
        data () {
            return {
                sendCodaAndSodaByMail: this.clientV2.sendCodaAndSodaByMail,
                edit: false,
                componentKey: 0,
            };
        },
        watch: {
            clientV2 () {
                this.resetProperty();
            },
        },
        mounted () {
            this.resetProperty();
        },
        emits: ['clientupdated'],
        methods: {
            resetProperty () {
                this.sendCodaAndSodaByMail = this.clientV2.sendCodaAndSodaByMail;
            },
            handleEdit () {
                this.edit = true;
            },
            handleCancel () {
                this.$refs.clientForm.resetForm();
                this.edit = false;
            },
            forceRerender () {
                this.componentKey += 1;
            },
            async saveProperty (values) {
                Loader.start();
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation updateClient($clientId: String!, $input: ClientUpdateInput!) {
                    updateClient(clientId: $clientId, input: $input) {
                        errors { code, detail, source { pointer } }
                    }
                }`,
                    variables: {
                        clientId: this.clientV2.id,
                        input: {
                            sendCodaAndSodaByMail: values.sendCodaAndSodaByMail,
                        },
                    },
                });
                if (!data.updateClient.errors) {
                    this.sendCodaAndSodaByMail = values.sendCodaAndSodaByMail;
                    this.$emit('clientupdated');
                    this.edit = false;
                } else {
                    notify.error(this.$t('err-unknown'));
                    this.resetProperty();
                }
                this.forceRerender();
                Loader.start();
            },
        },
    };
</script>

<template>
    <Form
        ref='clientForm'
        id='clientInfo'
        @submit='saveProperty'
        v-slot='{ handleReset }'
        :key='componentKey'
    >
        <content-box
            :title='$t("ttl-settings")'
            class='mt-6'
        >
            <template #actions>
                <div v-if='!edit'>
                    <FriendlyButton
                        label='btn-edit'
                        symbol='pencil'
                        :action='handleEdit'
                        square
                        extra-small
                        no-margin
                    />
                </div>
                <template v-else>
                    <FriendlyButton
                        label='btn-cancel'
                        type='reset'
                        :action='() => { handleCancel();handleReset(); }'
                        symbol='times'
                        secondary
                        square
                        extra-small
                        no-margin
                        class='mr-2'
                    />
                    <FriendlyButton
                        label='btn-save'
                        type='submit'
                        symbol='check'
                        square
                        extra-small
                        no-margin
                    />
                </template>
            </template>
            <FormToggle
                :value='sendCodaAndSodaByMail'
                name='sendCodaAndSodaByMail'
                :label='organization.hasCodaOnlyContract ? $t("stngs-client-send-statement-dc") : $t("stngs-client-send-statement")'
                :edit='edit'
                :info='organization.hasCodaOnlyContract ? $t("stngs-client-send-statement-dc-info") : $t("stngs-client-send-statement-info")'
                id='sendCodaAndSodaByMail'
            />
        </content-box>
    </Form>
</template>
