<template>
    <div class='grid cb-env-footer'>
        <div class='grid-cols-12 text-center'>
            <img class='cb-footer-logo' src='@/assets/codabox-logo-full.svg'>
            <div class='cb-footer-body'>
                <div class='cb-footer-line'>
                    MyCodabox
                    <a href='https://www.codabox.com'>
                        Codabox S.A.
                    </a>
                </div>
                <div class='cb-footer-line small'>
                    Diestsepoort, 1 - 3000 Leuven
                </div>
                <div class='cb-footer-line small'>
                    <a href='tel:003228808480'>
                        <i class='fa fa-phone'></i>
                        +32 2 880 84 80
                    </a>

                    <span class='cb-hspace'> </span>

                    <a href='mailto:helpdesk@codabox.com'>
                        <i class='fa fa-envelope'></i>
                        helpdesk@codabox.com
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .cb-env-footer {
        padding: 100px 0;
        margin-top: auto;
    }

    .cb-footer-logo {
        height: 40px;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
    }

    .cb-footer-body {
        height: 64px;
        display: inline-block;
        text-align: left;
        margin-left: 10px;
        padding-top: 6px;
        color: gray;
    }

    .cb-footer-line {
        font-size: 14px;
    }

    .cb-footer-line.small {
        font-size: 12px;
    }

    .cb-footer-body .cb-hspace {
        margin-left: 10px;
    }
</style>
