<script>
    import CustomTitle from '@/components/Title.vue';
    import SecondaryNav from '@/components/SecondaryNav';
    import SecondaryNavPill from '@/components/SecondaryNavPill';

    export default {
        name: 'ClientTransfer',
        props: {
            organization: {
                type: Object,
            },
            currentEnvironment: {
                type: Object,
            },
        },
        components: {
            CustomTitle,
            SecondaryNav,
            SecondaryNavPill,
        },
        computed: {
            hasRelatedEnvironments () {
                return this.organization.environments.length > 1;
            },
        },
    };
</script>

<template>
    <div>
        <header>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-clients-transfer') }}
            </CustomTitle>

            <SecondaryNav v-if='hasRelatedEnvironments'>
                <SecondaryNavPill
                    :is-active='$route.name === "env-client-regular-transfer-list"'
                    :to='{ name: "env-client-regular-transfer-list"}'
                >
                    {{ $t('nav-env-client-regular-transfer-list') }}
                </SecondaryNavPill>
                <SecondaryNavPill
                    :is-active='$route.name === "env-client-platform-transfer-list"'
                    :to='{ name: "env-client-platform-transfer-list"}'
                >
                    {{ $t('nav-env-client-platform-transfer-list') }}
                </SecondaryNavPill>
            </SecondaryNav>
        </header>

        <router-view :current-environment='currentEnvironment' />
    </div>
</template>
