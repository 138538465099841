<script>
    import { dateFormat } from '@/filters';

    export default {
        name: 'CodaStateReason',
        props: {
            organization: { type: Object, required: true },
            mandate: {
                type: Object,
                required: true,
            },
            wide: {
                type: Boolean,
                default: false,
            },
            multiline: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                stateReasonSizeLimit: 20,
            };
        },
        computed: {
            signHereRejectionReason () {
                return (this.mandate.signHerePackage && this.mandate.signHerePackage.packageRejectionReason) || '';
            },
            stateReasonKey () {
                return {
                    'R-0': 'lgnd-reason-r-00',
                    'R-11': 'lgnd-reason-r-11',
                    'R-12': 'lgnd-reason-r-12',
                    'R-13': 'lgnd-reason-r-13',
                    'R-14': 'lgnd-reason-r-14',
                    'R-16': 'lgnd-reason-r-16',
                    'R-17': 'lgnd-reason-r-17',
                    'R-18': 'lgnd-reason-r-18',
                    'R-19': 'lgnd-reason-r-19',
                    'R-20': 'lgnd-reason-r-20',
                    'R-27': 'lgnd-reason-r-27',
                    'R-28': 'lgnd-reason-r-28',
                    'R-29': 'lgnd-reason-r-29',
                    'R-31': 'lgnd-reason-r-31',
                    'R-32': 'lgnd-reason-r-32',
                    'R-33': 'lgnd-reason-r-33',
                    'R-35': 'lgnd-reason-r-35',
                    'R-36': 'lgnd-reason-r-36',
                    'R-37': 'lgnd-reason-r-37',
                    'R-38': 'lgnd-reason-r-38',
                    'R-39': 'lgnd-reason-r-39',
                    'R-40': 'lgnd-reason-r-40',
                    'R-41': 'lgnd-reason-r-41',
                    'R-42': 'lgnd-reason-r-42',
                    'R-43': 'lgnd-reason-r-43',
                    'R-44': 'lgnd-reason-r-44',
                    'R-45': 'lgnd-reason-r-45',
                    'R-46': 'lgnd-reason-r-46',
                    'R-47': 'lgnd-reason-r-47',
                    'R-48': 'lgnd-reason-r-48',
                    'R-49': 'lgnd-reason-r-49',
                    'R-50': 'lgnd-reason-r-50',
                    'R-51': 'lgnd-reason-r-51',
                    'R-52': 'lgnd-reason-r-52',
                    'R-53': 'lgnd-reason-r-53',
                }[this.mandate.reasonCode] || 'lgnd-reason-r-00';
            },
            stateLegendKey () {
                return {
                    draft:            'lgnd-coda-pre-treatment',
                    'pre-treatment':    'lgnd-coda-pre-treatment',
                    'bank-procedure':   'lgnd-coda-bank-procedure',
                    'sent-hq':          'lgnd-coda-bank-procedure',
                    prepared:         'lgnd-coda-prepared',
                    'sent-client':      'lgnd-coda-sent-client',
                    problem:          'lgnd-coda-problem',
                    'available-online': 'lgnd-coda-available-online',
                    active:           'lgnd-coda-active',
                    archived:         'lgnd-coda-archived',
                    signed:           'lgnd-coda-signed',
                }[this.mandate.state];
            },
            notArchivedBankAccounts () {
                return this.mandate.bankAccounts.filter((ba) => ba.state !== 'archived');
            },
            isTwikey () {
                return this.mandate.bank.isTwikeySupported;
            },
            isSignhere () {
                return this.mandate.bank.isSignhereSupported;
            },
            isBankSupported () {
                return this.mandate.bank.isSupported;
            },
            reasonNote () {
                return this.mandate.reasonNote;
            },
            codaStateLegend () {
                if (['active', 'signed', 'prepared', 'sent-client', 'problem'].includes(this.mandate.state)) {
                    return this.stateLegendKey;
                }
                if (['pre-treatment', 'draft'].includes(this.mandate.state)) {
                    if (this.isBankSupported) {
                        return this.stateLegendKey;
                    } else {
                        return 'lgnd-coda-not-supported';
                    }
                }
                if (['sent-hq', 'bank-procedure'].includes(this.mandate.state)) {
                    if (this.bankAccountsHaveState('testing')) {
                        return 'lgnd-coda-bank-procedure-ba-testing';
                    } else if (this.notArchivedBankAccountsAllHaveFlowState('pending-until-next-coda-file')) {
                        if (this.mandate.bankId === 'KREDBEBB' || this.mandate.bankId === 'CREGBEBB') {
                            return 'lgnd-coda-bank-procedure-active-bank-mandate-kcbc';
                        } else {
                            return 'lgnd-coda-bank-procedure-active-bank-mandate';
                        }
                    } else if (this.mandate.bankId === 'KREDBEBB' || this.mandate.bankId === 'CREGBEBB') {
                        return 'lgnd-coda-bank-procedure-kbc' + this.dcTranslationKeySuffix;
                    } else if (this.mandate.reasonCode === 'R-32') {
                        return 'lgnd-reason-r-32';
                    } else {
                        return 'lgnd-coda-bank-procedure';
                    }
                }
                return null;
            },
            codaStateReason () {
                if (['problem', 'archived'].includes(this.mandate.state)) {
                    return this.stateReasonKey;
                }
                return null;
            },
            dcTranslationKeySuffix () {
                return this.organization.isDirectCustomer ? '-dc' : '';
            },
            signMandateInfoTranslationKey () {
                if (!['prepared', 'sent-client', 'available-online'].includes(this.mandate.state)) {
                    return null;
                }
                if (this.isTwikey || this.isSignhere) {
                    return 'lgnd-coda-mandate-digital-signing';
                }
                return 'lgnd-coda-mandate-paper-signing';
            },
            kbcNeedActivationTranslationKey () {
                if ((this.mandate.bankId === 'KREDBEBB' || this.mandate.bankId === 'CREGBEBB') &&
                    this.isSignhere &&
                        (!this.notArchivedBankAccountsAllHaveFlowState('draft-active-bank-mandate'))) {
                    return 'lgnd-coda-mandate-kcbc-activation';
                }
                return null;
            },
        },
        methods: {
            dateFormat,
            notArchivedBankAccountsAllHaveFlowState (flowState) {
                return this.notArchivedBankAccounts.every((ba) => ba.flowState === flowState);
            },
            bankAccountsHaveState (state) {
                return this.mandate.bankAccounts.some((ba) => ba.state === state);
            },
        },
    };
</script>

<template>
    <span
        class='cb-ellipsis'
        :class='{"wide": wide, "multiline": multiline}'
    >
        <span class='cb-ellipsis-text'>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-if='codaStateLegend' :data-translation-key='codaStateLegend' v-html='$t(codaStateLegend) + "&nbsp;"'></span>
            <span v-if='codaStateReason'>
                <span :data-translation-key='codaStateReason'>
                    {{ $t(codaStateReason, signHereRejectionReason) }}
                </span>
                <span v-if='reasonNote' data-translation-key='lgnd-coda-reason-note'>
                    {{ $t('lgnd-coda-reason-note') }} {{ reasonNote }};
                </span>
            </span>
            <span v-if='signMandateInfoTranslationKey'>
                <span v-if='kbcNeedActivationTranslationKey'>
                    <ol class='list-outside pl-8'>
                        <li :data-translation-key='signMandateInfoTranslationKey+dcTranslationKeySuffix'>{{ $t(signMandateInfoTranslationKey+dcTranslationKeySuffix) }}</li>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <li :data-translation-key='kbcNeedActivationTranslationKey+dcTranslationKeySuffix'><span v-html='$t(kbcNeedActivationTranslationKey+dcTranslationKeySuffix, {bank_name: mandate.bank.name})'></span></li>
                    </ol>
                </span>
                <span v-else :data-translation-key='signMandateInfoTranslationKey+dcTranslationKeySuffix'>
                    {{ $t(signMandateInfoTranslationKey+dcTranslationKeySuffix) }}
                </span>
            </span>
        </span>
    </span>
</template>

<style scoped>
  .cb-ellipsis {
      display: inline-block;
      position: relative;
      font-size: 0;
  }

  .cb-ellipsis:hover {
      display: block;
      z-index: 1000;
  }

  .cb-ellipsis-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
      display: inline-block;
      font-size: 1.2rem;
  }

  .wide {
      width: 90%;
  }

  .wide .cb-ellipsis-text {
      width: 100%;
      max-width: 100%;
  }

  .multiline .cb-ellipsis-text {
      white-space: normal;
  }

  .wide .cb-ellipsis-text {
      width: 100%;
      max-width: 100%;
  }
</style>
