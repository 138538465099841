<script>
    import { gql } from '@apollo/client/core';
    import notify from '@/notify.js';
    import _ from 'lodash';
    import { dateFormat } from '@/filters';

    export default {
        props: {
            currentEnvironment: {
                type: Object,
            },
        },
        data () {
            return {
                ready: false,
                loading: false,
                clientTransferRequests: [],
                stateCriteria: 'all',
                transferTypesFilter: [],
                statesFilter: [],
                nextPageCursor: null,
                limit: 20,
                pendingCancelRecord: null,
                isCancelPopupDisplayed: false,
            };
        },
        watch: {
            stateCriteria () {
                this.statesFilter = {
                    all: [],
                    open: ['new', 'problem', 'inProgress', 'waitingFiduFeedback', 'waitingClientConfirmation'],
                    closed: ['cancelled', 'done'],
                }[this.stateCriteria];

                this.fetchList();
            },
            'currentEnvironment.id': function () {
                this.fetchList();
            },
        },
        async mounted () {
            this.fetchList = _.throttle(this.fetchList, 500);
            this.fetchList();
            this.ready = true;
        },
        methods: {
            dateFormat,
            displayRowLandmark (i) {
                return Number.isInteger(((i + 1) / this.limit));
            },
            async fetchList () {
                this.loading = true;
                this.nextPageCursor = null;
                const search = await this.searchQuery();
                this.clientTransferRequests = search.results;
                this.nextPageCursor = search.nextPageCursor;
                this.loading = false;
            },
            async loadMore () {
                const search = await this.searchQuery();
                this.nextPageCursor = search.nextPageCursor;
                this.clientTransferRequests = [
                    ...this.clientTransferRequests,
                    ...search.results,
                ];
            },
            async searchQuery () {
                const results = await this.$apollo.query({
                    query: gql`query getClientTransferRequests($targetEnvironmentIds: [String], $transferTypes: [String], $states: [String], $nextPageCursor: String, $limit: Int) {
                        clientTransferRequests(targetEnvironmentIds:$targetEnvironmentIds, transferTypes:$transferTypes, states:$states, nextPageCursor:$nextPageCursor, limit:$limit) {
                            nextPageCursor,
                            results {
                                id,
                                state,
                                stateModifiedAt,
                                originalFiduciaryId,
                                originalFiduciaryName,
                                currentEnvironmentId,
                                clientId,
                                enterpriseName,
                                enterpriseNumber,
                                clientCode,
                                contactEmail,
                                exactEmail,
                            }
                        }
                    }`,
                    variables: {
                        targetEnvironmentIds: [this.currentEnvironment.id],
                        states: this.statesFilter,
                        transferTypes: this.transferTypesFilter,
                        nextPageCursor: this.nextPageCursor,
                        limit: this.limit,
                    },
                });
                return results.data.clientTransferRequests;
            },
            getStateLabel: function (state) {
                return {
                    new: 'ctr-state-new',
                    problem: 'ctr-state-processing',
                    inProgress: 'ctr-state-processing',
                    waitingClientConfirmation: 'ctr-state-waiting-client-confirmation',
                    waitingFiduFeedback: 'ctr-state-waiting-organization-feedback',
                    cancelled: 'ctr-state-cancelled',
                    done: 'ctr-state-done',
                }[state];
            },
            cancelRequest: function (record) {
                this.pendingCancelRecord = record;
                this.isCancelPopupDisplayed = true;
            },
            cancelConfirmation: async function () {
                const cancelMutation = await this.$apollo.mutate({
                    mutation: gql`mutation cancelConfirmation($clientTransferRequestId: String) {
                        cancelClientTransferRequest(clientTransferRequestId: $clientTransferRequestId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        clientTransferRequestId: this.pendingCancelRecord.id,
                    },
                });

                const cancelResponse = cancelMutation.data.cancelClientTransferRequest;

                if (cancelResponse.errors) {
                    notify.error(this.$t('error'));
                } else {
                    this.pendingCancelRecord.state = 'cancelled';
                    notify.success(this.$t('suc-client-transfer-request-cancelled'));
                }

                this.isCancelPopupDisplayed = false;
            },
            closeCancelPopup () {
                this.isCancelPopupDisplayed = false;
            },
        },
    };
</script>

<style lang='scss' scoped>
    .client-link {
        white-space: nowrap;
    }
</style>
