<script>
import { gql } from '@apollo/client/core';
import List from '@/components/List';
import ListRow from '@/components/ListRow';
import ListItem from '@/components/ListItem';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Tooltip from '@/components/Tooltip';
import { datadogRum } from '@datadog/browser-rum';
import Loader from '@/loader.js';
import Dropdown from '@/components/DropdownV2';
import DropdownGroup from '@/components/DropdownGroup';
import DropdownItem from '@/components/DropdownItem';
import { dateFormat } from '@/filters';

export default {
    name: 'ProblemsComponent',
    props: {
        organization: {
            type: Object,
        },
        currentEnvironment: {
            type: Object,
        },
        problemsOnly: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
        },
        noControls: {
            type: Boolean,
            default: false,
        },
        domain: {
            type: String,
            default: null,
        },
        hideIfEmpty: {
            type: Boolean,
            default: false,
        },
        loadingAmount: {
            type: Number,
            default: 1000,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        List,
        ListRow,
        ListItem,
        FriendlyButton,
        Tooltip,
        Dropdown,
        DropdownGroup,
        DropdownItem,
    },
    data () {
        return {
            listLoading: false,
            problems: [],
            paging: {
                limit: 10,
                offset: 0,
            },
            typeCriteria: 'all',
            filterTypes: [],
            domains: {
                clientInfo: [
                    'exactOnlineDeliveryProblem',
                    'contactEmailUndeliverable',
                ],
                coda: [
                    'kbcCbcIncompleteBankProcedure',
                    'kcbcBmStoppedByClient',
                ],
                soda: [],
                voila: [],
                caro: ['caroDeactivatedByClient'],
            },
            clientSubpagePerProblemType: {
                exactOnlineDeliveryProblem: '',
                contactEmailUndeliverable: '',
                kbcCbcIncompleteBankProcedure: '/coda',
                kcbcBmStoppedByClient: '/coda',
                caroDeactivatedByClient: '/caro',
            },
            loadLabel: 'lbl-load-all-insights',
            totalInsights: 0,
        };
    },
    computed: {
        environmentId () {
            return this.currentEnvironment.id;
        },
        domainFilteredProblems () {
            let domainFilteredProblems = this.problems;
            if (this.domain) {
                domainFilteredProblems = this.problems.filter(p => this.domains[this.domain].includes(p.type));
            }
            this.$emit('domainFilteredProblemsChanged', domainFilteredProblems);
            return domainFilteredProblems;
        },
        isEnvExactOnline () {
            return this.currentEnvironment.isExactOnline;
        },
        showOnlyCoda () {
            return this.organization.hasCodaOnlyContract;
        },
        isDirectCustomer () {
            return this.organization.isDirectCustomer;
        },
        hasProblems () {
            return this.problems.length > 0;
        },
    },
    watch: {
        typeCriteria () {
            if (this.typeCriteria === '' || this.typeCriteria === 'all') {
                this.filterTypes = [];
            } else {
                this.filterTypes = this.typeCriteria;
            }
            this.updateFilteredResults();
        },
        'currentEnvironment.id': async function () {
            await this.loadList();
        },
    },
    async beforeMount () {
        await this.loadList();
    },
    emits: ['loaded', 'domainFilteredProblemsChanged'],
    methods: {
        dateFormat,
        async loadList () {
            this.listLoading = true;
            try {
                this.filterTypes = [];
                const search = await this.searchProblems();
                this.problems = search.results;
                this.$emit('loaded', this.problems);
                this.paging = search.paging;
            } catch (e) { } finally {
                this.listLoading = false;
            }
        },
        async searchProblems (limit = this.paging.limit, offset = this.paging.offset) {
            if (this.clientId) {
                const { data } = await this.$apollo.query({
                    query: gql`
                      query ProblemSearches($environmentId: String, $clientId: String, $types: [String], $paging: PaginationInput, $kbcDayOffset: Int) {
                      searchProblemsByClient(environmentId: $environmentId, clientId: $clientId, types: $types, paging: $paging, kbcDayOffset: $kbcDayOffset) {
                          results {
                               id,
                               type,
                               objectRef,
                               created,
                          }
                          paging {
                              limit,
                              offset,
                              count
                          }
                      }
                  }`,
                    variables: {
                        environmentId: this.environmentId,
                        clientId: this.clientId,
                        // type can only be add when the problem type is created, otherwise an error will be returned (and we will crash)
                        types: ['exactOnlineDeliveryProblem', 'contactEmailUndeliverable', 'kbcCbcIncompleteBankProcedure', 'caroDeactivatedByClient', 'kcbcBmStoppedByClient'],
                        paging: {
                            limit,
                            offset,
                        },
                        kbcDayOffset: this.$route.query.kbcDayOffset ? parseInt(this.$route.query.kbcDayOffset) : 7,
                    },
                });

                return data.searchProblemsByClient;
            } else {
                const { data } = await this.$apollo.query({
                    query: gql`
                    query ProblemSearches($environmentId: String, $types: [String], $paging: PaginationInput, $kbcDayOffset: Int) {
                    searchProblemsByEnvironment(environmentId: $environmentId, types: $types, paging: $paging, kbcDayOffset: $kbcDayOffset) {
                        results {
                             id,
                             type,
                             created,
                             objectRef,
                             client {
                                 id,
                                 enterpriseName,
                             }
                        }
                        paging {
                            limit,
                            offset,
                            count
                        }
                    }
                }`,
                    variables: {
                        environmentId: this.environmentId,
                        types: this.filterTypes,
                        paging: {
                            limit,
                            offset,
                        },
                        kbcDayOffset: this.$route.query.kbcDayOffset ? parseInt(this.$route.query.kbcDayOffset) : 7,
                    },
                });

                this.totalInsights = data.searchProblemsByEnvironment.paging.count;
                if (this.totalInsights > 1000) {
                    this.loadLabel = 'btn-problems-loadmore';
                }
                return data.searchProblemsByEnvironment;
            }
        },
        problemType (problemType) {
            return this.$t({
                exactOnlineDeliveryProblem: 'lbl-problem-exact-online-delivery-problem',
                contactEmailUndeliverable: 'lbl-problem-contact-email-undeliverable',
                kbcCbcIncompleteBankProcedure: 'lbl-problem-kbc-cbc-incomplete-bank-procedure',
                kcbcBmStoppedByClient: 'lbl-problem-kcbc-bm-stopped-by-client',
                kcbcBmStoppedByClientCv: 'lbl-problem-kcbc-bm-stopped-by-client-cv',
                caroDeactivatedByClient: 'lbl-problem-caro-deactivated-by-client',
            }[problemType]);
        },
        problemTypeExplanation (problemType) {
            const labels = {
                caroDeactivatedByClient: 'lbl-problem-explanation-caro-deactivated-by-client',
            };
            if (problemType in labels) {
                return this.$t(labels[problemType]);
            }
            return '';
        },
        addLog (log) {
            datadogRum.addAction(log);
        },
        async loadMore () {
            Loader.start();
            const search = await this.searchProblems(
                this.loadingAmount,
                this.problems.length,
            );
            this.problems = [
                ...this.problems,
                ...search.results,
            ];
            this.$emit('loaded', this.problems);
            this.paging = search.paging;
            Loader.stop();
        },
        displayRowLandmark (i) {
            return Number.isInteger(((i + 1) / this.loadingAmount));
        },
        async updateFilteredResults () {
            this.paging.limit = 10;
            this.paging.offset = 0;
            const search = await this.searchProblems();
            this.problems = search.results;
            this.paging = search.paging;
        },
    },
};
</script>

<template>
    <div v-if='!(hideIfEmpty && domainFilteredProblems.length === 0)'>
        <div class='flex mb-3'>
            <Dropdown
                v-model='typeCriteria'
                :name='$t("lbl-problem-type")'
                class='z-10'
                nullable
                icon='ArrowsUpDown'
                id='insights-type-filter'
                default-value='all'
                v-if='!clientId'
            >
                <DropdownGroup>
                    <DropdownItem id='all' :name='$t("lbl-all")' default class='whitespace-nowrap'>
                        {{ $t('lbl-all') }}
                    </DropdownItem>
                    <DropdownItem
                        id='exactOnlineDeliveryProblem'
                        :name='$t("lbl-problem-exact-online-delivery-type")'
                        v-if='isEnvExactOnline'
                        class='whitespace-nowrap'
                    >
                        {{ $t("lbl-problem-exact-online-delivery-type") }}
                    </DropdownItem>
                    <DropdownItem
                        id='contactEmailUndeliverable'
                        :name='$t("lbl-problem-contact-email-undeliverable-type")'
                        class='whitespace-nowrap'
                    >
                        {{ $t("lbl-problem-contact-email-undeliverable-type") }}
                    </DropdownItem>
                    <DropdownItem
                        id='kbcCbcIncompleteBankProcedure'
                        :name='$t("lbl-problem-kbc-cbc-incomplete-bank-procedure-type")'
                        class='whitespace-nowrap'
                    >
                        {{ $t("lbl-problem-kbc-cbc-incomplete-bank-procedure-type") }}
                    </DropdownItem>
                    <DropdownItem
                        id='kcbcBmStoppedByClient'
                        :name='$t("lbl-problem-kcbc-bm-stopped-by-client-type")'
                        class='whitespace-nowrap'
                    >
                        {{ $t("lbl-problem-kcbc-bm-stopped-by-client-type") }}
                    </DropdownItem>
                    <DropdownItem
                        id='caroDeactivatedByClient'
                        :name='$t("lbl-problem-caro-deactivated-by-client-type")'
                        class='whitespace-nowrap'
                        v-if='!showOnlyCoda'
                    >
                        {{ $t("lbl-problem-caro-deactivated-by-client-type") }}
                    </DropdownItem>
                </DropdownGroup>
            </Dropdown>
        </div>
        <List
            id='delivery-problems'
            :class='{ "max-h-96": !fullHeight }'
            :loading='listLoading'
            no-border
            :scrollable='scrollable'
            v-if='domainFilteredProblems.length > 0 || listLoading'
        >
            <template #rows>
                <ListRow
                    v-for='(problem, i) in domainFilteredProblems'
                    :key='i'
                    :background='!Number.isInteger(((i + 1) / 2))'
                    :landmark='displayRowLandmark(i)'
                >
                    <ListItem>
                        <div class='flex flex-col h-full py-3' v-if='!problemsOnly'>
                            <router-link
                                v-if='problem.client'
                                :to='`/organization/${$route.params.organizationId}/environment/${$route.params.environmentId}/client/${problem.client.id}${clientSubpagePerProblemType[problem.type]}`'
                            >
                                <div
                                    @click='addLog(`problemModule${problem.type.charAt(0).toUpperCase()}${problem.type.slice(1)}`)'
                                >
                                    {{ problem.client.enterpriseName }}
                                </div>
                            </router-link>
                            <template v-else>
                                N/A
                            </template>
                        </div>
                        <div v-else>
                            <span v-if='problem.type === "kcbcBmStoppedByClient"'>{{ problemType(problem.type + 'Cv') }} <strong>{{ problem.objectRef }}</strong></span>
                            <span v-else>{{ problemType(problem.type) }}</span>
                            <strong>{{ problemTypeExplanation(problem.type) }}</strong>
                            <Tooltip class='ml-2' large>
                                <template #trigger>
                                    <span
                                        class='text-blue-400 underline underline-offset-2 cursor-pointer hover:text-blue-300'
                                    >
                                        {{ $t('lbl-problem-resolution-cta') }}
                                    </span>
                                </template>
                                <template #content>
                                    <div v-if='problem.type === "exactOnlineDeliveryProblem"'>
                                        <p>
                                            {{ $t('p-problem-resolution-exact-online-delivery-p-1') }}
                                        </p>
                                        <p>
                                            {{ $t('p-problem-resolution-exact-online-delivery-p-2') }}
                                        </p>
                                    </div>
                                    <div v-if='problem.type === "contactEmailUndeliverable"'>
                                        {{ $t('p-problem-resolution-contact-email-undeliverable-p-1') }}
                                    </div>
                                    <div v-if='problem.type === "kbcCbcIncompleteBankProcedure"'>
                                        {{ $t('p-problem-resolution-kbc-cbc-not-linked-p-1-new-mandate') }} <br>
                                        {{ $t('p-problem-resolution-kbc-cbc-not-linked-p-2') }} <br>
                                        {{ $t('p-problem-resolution-kbc-cbc-not-linked-p-3') }} <a
                                            :href='isDirectCustomer ? $t("p-problem-resolution-kbc-cbc-incomplete-bank-procedure-faq-link-dc") : $t("p-problem-resolution-kbc-cbc-incomplete-bank-procedure-faq-link")'
                                            target='_blank'
                                        >FAQ</a>.
                                    </div>
                                    <div v-if='problem.type === "kcbcBmStoppedByClient"'>
                                        {{ $t('p-problem-resolution-kbc-cbc-not-linked-p-1-active-mandate') }} <br><br>
                                        <span v-if='isDirectCustomer' v-html='$t("lgnd-coda-mandate-kcbc-activation-dc", {"bank_name": "KBC/CBC"})'></span>
                                        <span v-else v-html='$t("lgnd-coda-mandate-kcbc-activation", {"bank_name": "KBC/CBC"})'></span>
                                    </div>
                                    <div v-if='problem.type === "caroDeactivatedByClient"'>
                                        {{ $t('p-problem-resolution-caro-deactivated-by-client-p-1') }}
                                    </div>
                                </template>
                            </Tooltip>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div class='flex flex-col items-end h-full whitespace-nowrap'>
                            <div v-if='!problemsOnly' class='text-right'>
                                {{ problemType(problem.type) }}
                            </div>
                            <div class='text-grey-300 mt-1'>
                                {{ $t('lbl-problem-date') }} {{ dateFormat(problem.created) }}
                            </div>
                        </div>
                    </ListItem>
                </ListRow>
            </template>
        </List>
        <div v-else>
            <div class='text-lg text-grey-300'>
                {{ $t('p-problem-emtpy') }} 🎉
            </div>
        </div>
        <div class='text-grey-300 mt-6 text-xl' v-if='!noControls && hasProblems'>
            {{ problems.length }} / {{ paging.count }}

            <FriendlyButton
                :label='loadLabel'
                :action='loadMore'
                id='btn-loadmore'
                extra-small
                square
                :disabled='(problems.length >= paging.count) || listLoading'
            />
        </div>
    </div>
</template>
